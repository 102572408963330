export const VIDEO_PRIVATE = '/videos/player'
export const VIDEO_PUBLIC = '/public/player'
export const ANALYTICS = '/public/metrics'
export const API_URLS = {
  VIDEO_PRIVATE: {
    path: VIDEO_PRIVATE,
    createURL: (videoId) => `${VIDEO_PRIVATE}/${videoId}`,
  },
  VIDEO_PUBLIC: {
    path: VIDEO_PUBLIC,
    createURL: (videoId) => `${VIDEO_PUBLIC}/${videoId}`,
  },
  ANALYTICS: {
    path: ANALYTICS,
    createURL: () => `${ANALYTICS}`,
  },
}
