const fakeScormEnabled = process.env.REACT_APP_USE_FAKE_SCORM === 'true'
const fakeScormVersion = process.env.REACT_APP_FAKE_SCORM_VERSION || '2004'

const COMPLETION_STATUS = {
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
}

const SUCCESS_STATUS = {
  PASSED: 'passed',
  FAILED: 'failed',
}

const RESULT = {
  1.2: { CORRECT: 'correct', INCORRECT: 'wrong' },
  2004: { CORRECT: 'correct', INCORRECT: 'incorrect' },
}

const fieldMapping = {
  studentId: {
    1.2: 'cmi.core.student_id',
    2004: 'cmi.learner_id',
  },
  studentEmail: {
    1.2: 'cmi.core.student_id',
    2004: 'cmi.learner_id',
  },
  studentName: {
    1.2: 'cmi.core.student_name',
    2004: 'cmi.learner_name',
  },
  scoreMin: {
    1.2: 'cmi.core.score.min',
    2004: 'cmi.score.min',
  },
  scoreMax: {
    1.2: 'cmi.core.score.max',
    2004: 'cmi.score.max',
  },
  scoreRaw: {
    1.2: 'cmi.core.score.raw',
    2004: 'cmi.score.raw',
  },
  exit: {
    1.2: 'cmi.core.exit',
    2004: 'cmi.exit',
  },
  timeSpent: {
    1.2: 'cmi.core.session_time',
    2004: 'cmi.session_time',
  },
  successStatus: {
    1.2: 'cmi.core.lesson_status',
    2004: 'cmi.success_status',
  },
}

const mapField = (field, version) => {
  if (!fieldMapping[field]) return field

  if (typeof fieldMapping[field] === 'object') {
    return fieldMapping[field][version]
  }
  return fieldMapping[field]
}

const formatCustomDate = (date) => {
  const pad = (num, size = 2) => String(num).padStart(size, '0')

  const year = date.getFullYear()
  const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())

  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  const seconds = pad(date.getSeconds())
  const milliseconds = Math.floor(date.getMilliseconds() / 100)

  const timezoneOffset = -date.getTimezoneOffset() / 60
  const timezoneSign = timezoneOffset >= 0 ? '+' : '-'
  const timezone = `${timezoneSign}${pad(Math.abs(timezoneOffset))}`

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezone}`
}

const fakeScorm = {
  init: () => {
    console.log('SCORM: init')
    return true
  },
  version: fakeScormVersion,
  set: (...args) => console.log('SCORM: set', ...args),
  get: (...args) => {
    console.log('SCORM: get', ...args)
    const key = args[0]
    if (Object.values(fieldMapping.studentEmail).includes(key)) return 'test@test.com'
    if (Object.values(fieldMapping.studentName).includes(key)) return 'Test User'
    return null
  },
  save: (...args) => {
    console.log('SCORM: save', ...args)
    return true
  },
  quit: (...args) => console.log('SCORM: quit', ...args),
}

/**
 * @property {string} studentId
 * @property {string} studentEmail
 * @property {string} studentName
 * @property {string} score - 0..1
 * @property {string} exit - suspend|logout|normal
 * @property {number} timeSpent
 * @property {string} completionStatus - completed|incomplete
 * @property {string} successStatus - passed|failed
 */
class Scorm {
  #enabled = false
  #completed = false
  #version = null
  #score = 0
  #closed = false

  interactionSequenceId = 0

  constructor() {
    this.scormApi = window.videoData ? window.pipwerks.SCORM : fakeScormEnabled ? fakeScorm : null
    if (!this.scormApi) return

    const ctx = this
    Object.keys(fieldMapping).forEach((key) => {
      Object.defineProperty(ctx, key, {
        get: () => ctx.get(key),
        set: (value) => {
          ctx.set(key, value)
        },
        enumerable: true,
        configurable: false,
      })
    })
  }

  init() {
    if (!this.scormApi || this.#enabled) return
    this.#enabled = this.scormApi.init()
    this.#version = this.scormApi.version
  }

  close() {
    if (!this.#enabled || this.#closed) return
    if (this.#completed) this.progress = 1
    this.scormApi.save()
    this.scormApi.quit()
    this.#closed = true
  }

  save() {
    if (!this.#enabled) return
    this.scormApi.save()
  }

  get enabled() {
    return this.#enabled
  }

  get version() {
    return this.#version
  }

  set progress(value) {
    if (!this.#enabled || this.#version !== '2004') return
    this.scormApi.set('cmi.progress_measure', value)
  }

  set completed(value) {
    const status = value ? COMPLETION_STATUS.COMPLETED : COMPLETION_STATUS.INCOMPLETE
    if (this.#version === '2004') this.scormApi.set('cmi.completion_status', status)
    if (value) {
      this.success = this.#score >= 0.7
      this.#completed = true
    } else if (this.#version === '1.2') this.scormApi.set('cmi.core.lesson_status', status)
    this.save()
  }

  get completed() {
    return this.#completed
  }

  set success(value) {
    if (!this.#enabled) return
    const status = value ? SUCCESS_STATUS.PASSED : SUCCESS_STATUS.FAILED
    this.set('successStatus', status)
  }

  objective(sequenseId, result, description) {
    const objectivesPrefix = `cmi.objectives.${sequenseId + 1}`
    const objectiveId = `OBJ${sequenseId}`
    this.scormApi.set(`${objectivesPrefix}.id`, objectiveId)
    this.scormApi.set(`${objectivesPrefix}.score.min`, '0')
    this.scormApi.set(`${objectivesPrefix}.score.max`, '100')
    if (this.#version === '2004') {
      this.scormApi.set(`${objectivesPrefix}.score.scaled`, result ? '1' : '0')
      this.scormApi.set(`${objectivesPrefix}.success_status`, result ? 'passed' : 'failed')
    } else {
      this.scormApi.set(`${objectivesPrefix}.score.raw`, result ? '100' : '0')
      this.scormApi.set(`${objectivesPrefix}.status`, result ? 'passed' : 'failed')
    }
    this.scormApi.set(`${objectivesPrefix}.completion_status`, 'completed')
    this.scormApi.set(`${objectivesPrefix}.progress_measure`, result ? '1' : '0')
    this.scormApi.set(`${objectivesPrefix}.description`, description)
    this.scormApi.save()

    return objectiveId
  }

  interaction({ type = 'choice', description, correctResponse, userResponse, result, weighting = 1 }) {
    if (!this.#enabled) return

    const sequenseId = this.interactionSequenceId++
    const prefix = `cmi.interactions.${sequenseId}`

    // TODO: remove after debugging on real LMS
    console.log(`${prefix}.result`, {
      type,
      description,
      correctResponse,
      userResponse,
      result,
      weighting,
    })

    // in first save objectives
    const objectiveId = this.objective(sequenseId, result, description)

    // Map interaction data to cmi.interaction keys
    this.scormApi.set(`${prefix}.id`, `QUESTION${sequenseId}`)
    this.scormApi.set(`${prefix}.type`, type)
    if (this.#version === '2004') this.scormApi.set(`${prefix}.description`, description)

    if (this.#version === '1.2') this.scormApi.set(`${prefix}.student_response`, userResponse)
    if (this.#version === '2004') {
      this.scormApi.set(`${prefix}.learner_response`, userResponse)
      this.scormApi.set(`${prefix}.timestamp`, formatCustomDate(new Date()))
      // TODO temporary set 10sec for answer. Need a real calculation between show quiz and receiving answer
      // this.scormApi.set(`${prefix}.latency`, 'PT10S')
    }

    this.scormApi.set(`${prefix}.result`, result ? RESULT[this.#version].CORRECT : RESULT[this.#version].INCORRECT)
    this.scormApi.set(`${prefix}.weighting`, weighting)
    this.scormApi.set(`${prefix}.correct_responses.0.pattern`, correctResponse)
    this.scormApi.set(`${prefix}.objectives.0.id`, objectiveId)
    this.scormApi.save()
  }

  set score(value) {
    this.#score = value
    this.set('scoreMin', '0')
    this.set('scoreMax', '100')
    this.set('scoreRaw', Math.round(value * 100).toString())
    if (this.#version === '2004') {
      this.scormApi.set('cmi.score.scaled', value)
    }
  }

  get(key) {
    if (!this.#enabled) return null
    return this.scormApi.get(mapField(key, this.#version))
  }

  set(key, value) {
    if (!this.#enabled) return false
    return this.scormApi.set(mapField(key, this.#version), value)
  }
}

export default Scorm
