import { BROWSER_ENUM, OS_ENUM } from '../configs'

const isIphoneWithSafari =
  window.navigator.userAgent.includes(OS_ENUM.IPHONE) && window.navigator.userAgent.includes(BROWSER_ENUM.SAFARI)
const isMacWithSafari =
  window.navigator.userAgent.includes(OS_ENUM.MAC) && window.navigator.userAgent.includes(BROWSER_ENUM.SAFARI)

export const NEED_EXTENDED_RANGE = isIphoneWithSafari || isMacWithSafari
export const MIN_GAP_BETWEEN_EVENTS = NEED_EXTENDED_RANGE ? 0.1 : 0.04
export const GAP_FOR_NAVIGATION_EVENTS = MIN_GAP_BETWEEN_EVENTS * 3
export const RANGE_BOUND = NEED_EXTENDED_RANGE ? 0.1 : 0.04
export const TIMESHIFT_SLIDE_END_PLAY = RANGE_BOUND * 2
