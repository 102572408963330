import React from 'react'
import { useClientContext, usePlayerContext, useVideoDataContext } from '../hooks'
import { CONTROLS_HEIGHT } from '../configs'
import { TIMESHIFT_SLIDE_END_PLAY } from '../utils'
import { createIsTimeKeyInRangeWithCurrentTime } from '../utils/interactivity-helpers'

const VideoContainer = ({ videoElementRef }) => {
  const {
    playHandler,
    pauseHandler,
    isPaused,
    isVideoReady,
    isPlayDisabled,
    isVideoFinished,
    playerBounding,
    currentSubtitles,
    isSubtitlesVisible,
    controlVisibilityMode,
    rewindOnTimeHandler,
  } = usePlayerContext()
  const { isIOS, isFullScreen } = useClientContext()
  const { videoSchedule, video } = useVideoDataContext()

  const { hasSubtitles } = videoSchedule ?? {}
  const { url, subtitlesLanguage, subtitlesUrl } = video ?? {}

  const shouldTransformToBottom = isFullScreen && playerBounding.y > CONTROLS_HEIGHT / 1.8

  const handlePlayPause = () => {
    if (isPlayDisabled) return
    if (isPaused && isVideoFinished) rewindOnTimeHandler(0, true)
    if (isPaused) {
      const { slidesSchedule } = videoSchedule
      const { currentTime } = videoElementRef.current
      const isInRange = createIsTimeKeyInRangeWithCurrentTime(currentTime)
      const isSlideEnd = slidesSchedule.find(({ endTime }) => isInRange(endTime))
      const timeShift = isSlideEnd ? TIMESHIFT_SLIDE_END_PLAY : 0
      playHandler(timeShift)
    } else {
      pauseHandler()
    }
  }

  return (
    <>
      {hasSubtitles && isSubtitlesVisible && (
        <div
          className="player-view--subtitles"
          data-state={`controls-${shouldTransformToBottom ? 'hidden' : controlVisibilityMode}`}
          style={{ fontSize: playerBounding?.width / 40, paddingInline: playerBounding.width / 100 }}
        >
          {currentSubtitles}
        </div>
      )}
      <video
        id="video"
        src={url}
        preload="metadata"
        ref={videoElementRef}
        crossOrigin="anonymous"
        playsInline={isIOS ? true : undefined}
        style={{ height: !isVideoReady ? window.innerWidth / 1.77777 : '' }}
        onClick={handlePlayPause}
        className={`player-root--video${isPlayDisabled ? ' player-root--video-play-disabled' : ''}`}
      >
        {hasSubtitles && isSubtitlesVisible && (
          <track
            default={true}
            kind="subtitles"
            // if variable is false then src will have false as a value instead of a string
            src={subtitlesUrl || ''}
            label={subtitlesLanguage}
            srcLang={subtitlesLanguage}
          />
        )}
        Your browser doesn't&apost support video player
      </video>
    </>
  )
}

export default VideoContainer
