import { createGroupedHashMap, sortASC } from '../helpers'
import { ButtonBuilder } from './button-adapter'
import { isButton, isLink, isQuizSubObject } from './helper-fns'
import { LinkBuilder } from './link-adapter'
import { QuizBuilder } from './quiz-adapter'

// InteractiveItemsFabric processes objects and find out which classes should be used
export class InteractiveItemsFabric {
  constructor(slideData, objects = [], defaultObjectsGroupedHashMap = {}, slideObjectsHashMap = {}) {
    const { slide } = slideData
    const slideId = slide.id
    // grouping objects by theirs types
    const groupedObjects = objects.reduce(
      (accumulator, object) => {
        const { quizesObjects, linksObjects, buttonsObjects } = accumulator
        switch (true) {
          case isQuizSubObject(object):
            quizesObjects.push(object)
            break
          case isLink(object):
            linksObjects.push(object)
            break
          case isButton(object):
            buttonsObjects.push(object)
            break
          default:
            break
        }
        return { quizesObjects, linksObjects, buttonsObjects }
      },
      {
        quizesObjects: [],
        linksObjects: [],
        buttonsObjects: [],
      },
    )
    const { quizesObjects, linksObjects, buttonsObjects } = groupedObjects

    // save meta information about slide's interactive elements
    this.slideId = slideId
    this.hasQuizes = quizesObjects.length > 0
    this.hasLinks = linksObjects.length > 0
    this.hasButtons = buttonsObjects.length > 0
    this.hasInteractivity = this.hasQuizes || this.hasLinks || this.hasButtons

    // buid adapters for every interactive elements that will help in React components
    // while video will be playing
    this.quizes =
      QuizBuilder.buildQuizes(quizesObjects, slideData, defaultObjectsGroupedHashMap, slideObjectsHashMap) ?? []
    this.buttons = ButtonBuilder.buildButtons(buttonsObjects, slideData, slideObjectsHashMap) ?? []
    this.links = LinkBuilder.buildLinks(linksObjects, slideData, slideObjectsHashMap) ?? []
  }
  get interactiveItemsSchedule() {
    const timeToItemsGroupedHashMap = {}
    // collect all possible items into single array
    const sortedItemsMeta = [...this.quizes, ...this.buttons, ...this.links]
      // map over to calc start time for every item and add some meta info including item itself
      .map((item) => ({
        slideId: this.slideId,
        id: item.id,
        startTime: item.startTime,
        endTime: item.endTime,
        item,
      }))
      // sorting by startTime ASC
      .sort((a, b) => sortASC(a.startTime, b.startTime))
    // create time to items grouped hash map like -> { time: items[] }
    sortedItemsMeta.forEach(createGroupedHashMap({ fieldName: 'startTime', hashMap: timeToItemsGroupedHashMap }))
    // return a tuple of times array (delete duplicates) and time to items grouped hash map
    return [[...new Set(sortedItemsMeta.map(({ startTime }) => startTime))], timeToItemsGroupedHashMap]
  }
}
