export const THEME_CONFIG = {
  palette: {
    colorPrimary: '#4868ff',
    colorPrimaryActive: '#2d4ffc',
    colorPrimaryHover: 'rgba(72, 102, 255, 0.8)',
    colorLink: '#4868ff',
    colorLinkActive: '#2d4ffc',
    colorLinkHover: 'rgba(72, 102, 255, 0.8)',
    colorError: '#cf5d60',
  },
  fonts: {
    defaultFont: `'Basier Circle', Arial, sans-serif`,
  },
}
