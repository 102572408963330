import { createContext, useCallback, useEffect, useState } from 'react'
import { noop } from '../utils'
import { ARIA_LIVE_REGION_HTML_ID } from '../constants/constants'

const defaultContextValue = {
  showNotification: noop,
  ariaLiveRegion: document.getElementById(ARIA_LIVE_REGION_HTML_ID),
  messageApi: null,
}

export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  LOADING: 'loading',
}

export const ARIA_LIVE_MODES = {
  POLITE: 'polite',
  ASSERTIVE: 'assertive',
  OFF: 'off',
}

export const NotificationContext = createContext(defaultContextValue)

export const NotificationContextProvider = ({ children, messageApi }) => {
  const [contextValue, setContextValue] = useState(defaultContextValue)

  const showNotification = useCallback(
    (messageProps, a11yText, messageType = MESSAGE_TYPE.SUCCESS, ariaLiveMode = ARIA_LIVE_MODES.POLITE) => {
      if (!messageProps) return
      messageApi[messageType](messageProps)
      const ariaLiveRegion = contextValue.ariaLiveRegion
      if (typeof messageProps.content === 'string' && a11yText && ariaLiveRegion) {
        ariaLiveRegion.innerText = a11yText
        ariaLiveRegion.setAttribute('aria-live', ariaLiveMode)
      }
    },
    [messageApi],
  )
  useEffect(() => {
    if (!messageApi) return
    setContextValue((ctx) => ({ ...ctx, showNotification, messageApi }))
  }, [messageApi])

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
}
