import { useState } from 'react'
import { secondsToHMS } from '../../utils'
import { Button, Dropdown } from 'antd'
import Icon, { IconsENUM } from '../UI/Icons/Icon'
import { usePlayerContext, useVideoDataContext } from '../../hooks'

export const ChapterMenu = (props) => {
  const { marks } = props
  const { videoSchedule } = useVideoDataContext()
  const { rewindOnTimeHandler, isPaused } = usePlayerContext()
  const [isChapterMenuOpen, setIsChapterMenuOpen] = useState(false)

  const { isNavigationAllowed } = videoSchedule ?? {}

  const selectChapterHandler = (event) => {
    if (!isNavigationAllowed) return
    rewindOnTimeHandler(event.key, !isPaused)
  }

  const chapterMenuItems = [
    { key: 'title', label: <div>Chapters</div> },
    ...marks
      .filter((m) => m.chapter)
      .map((m, index, filteredMarks) => ({
        key: index > 0 ? filteredMarks[index - 1].nextSlideBeginTime : 0,
        label: (
          <div>
            <span>{secondsToHMS(index > 0 ? filteredMarks[index - 1].nextSlideBeginTime : 0)}</span>
            <span>{m.chapter}</span>
          </div>
        ),
      })),
  ]

  if (chapterMenuItems.length === 1) return null

  return (
    <Dropdown
      trigger={['click']}
      placement="top"
      open={isChapterMenuOpen}
      arrow={{ pointAtCenter: true }}
      onOpenChange={setIsChapterMenuOpen}
      overlayClassName="interactive-player-menu chapter-menu"
      menu={{ items: chapterMenuItems, onClick: selectChapterHandler }}
      getPopupContainer={() => document.getElementById('menu-container')}
    >
      <Button aria-label="Open chapter's popup" shape="circle" type="text">
        <Icon name={IconsENUM.card} />
      </Button>
    </Dropdown>
  )
}
