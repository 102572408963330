import { motion } from 'framer-motion'
import { useAnalyticContext, usePlayerContext } from '../../../hooks'
import { transitionTemplate } from '../../../constants/constants'

export const InteractiveLink = (props) => {
  const { interactiveItem, onInteractivityFinished } = props
  const { pauseHandler, playHandler } = usePlayerContext()
  const { playerBounding } = usePlayerContext()
  const { analyticCollector } = useAnalyticContext()

  const onClickHandler = () => {
    pauseHandler()
    analyticCollector.setClick()
    onInteractivityFinished(playHandler, true)
  }

  const { link: href } = interactiveItem.getActionStrategy()
  const { object, getElementStyles } = interactiveItem
  return (
    <motion.a
      href={href}
      target="_blank"
      rel="noreferrer noopenner"
      onClick={onClickHandler}
      {...transitionTemplate}
      style={{
        // display: 'block',
        ...getElementStyles(playerBounding, object),
      }}
    >
      <span className="sr-only">Link</span>
      {/* for a11y purpose there should be added some text that describes a link's target */}
    </motion.a>
  )
}
