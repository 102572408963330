import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../configs'

const secondsToHMS = (totalSeconds) => {
  totalSeconds = Math.ceil(totalSeconds)
  let hours = Math.floor(totalSeconds / 3600)
  hours = hours === 0 ? '' : hours + ':'
  totalSeconds %= 3600
  let minutes = Math.floor(totalSeconds / 60)
  minutes = minutes < 10 && hours.length ? '0' + minutes + ':' : minutes + ':'
  let seconds = Math.ceil(totalSeconds % 60)
  seconds = seconds < 10 ? '0' + seconds : seconds
  return totalSeconds ? `${hours}${minutes}${seconds}` : '0:00'
}
const noop = () => {}
const sortASC = (a, b) => a - b
const sortDESC = (a, b) => b - a
const createHashMap =
  ({ fieldName = 'id', hashMap = {} }) =>
  (obj) => {
    if (typeof obj !== 'object') return hashMap
    const keyValue = obj[fieldName]
    hashMap[keyValue] = obj
    return hashMap
  }

const createGroupedHashMap =
  ({ fieldName = 'id', hashMap = {} }) =>
  (obj) => {
    if (typeof obj !== 'object') return hashMap
    const keyValue = obj[fieldName]
    const currentValues = hashMap[keyValue]
    if (!Array.isArray(currentValues)) {
      hashMap[keyValue] = [obj]
    } else {
      hashMap[keyValue].push(obj)
    }
    return hashMap
  }

const getBoundingRectForElement = (element) => {
  if (!element) return
  const bounding = element.getBoundingClientRect()
  if (bounding?.width / bounding?.height > 1.77777 && bounding.x === 0) {
    const newWidth = bounding.height * 1.77777
    bounding.x = (bounding.width - newWidth) / 2
    bounding.width = newWidth
  }
  // TODO does we need separate ratio?
  const ratioX = bounding.width / CANVAS_WIDTH
  const ratioY = bounding.height / CANVAS_HEIGHT
  return { ...JSON.parse(JSON.stringify(bounding)), ratioX, ratioY }
}

export { secondsToHMS, noop, sortASC, sortDESC, createHashMap, createGroupedHashMap, getBoundingRectForElement }
