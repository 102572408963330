import { createContext, useCallback, useEffect, useState } from 'react'
import { LOCAL_STORAGE_TOKEN_KEY } from '../configs'
import { noop } from '../utils'

const defaultUserContextValue = {
  isRegistered: false,
  email: null,
  name: '',
  session: null,
  token: null,
  userId: null,
  setUserData: noop,
}
export const UserContext = createContext(defaultUserContextValue)

export const UserContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState(defaultUserContextValue)
  const setUserData = useCallback(({ email, session, userId, token }) => {
    const newValues = {}
    if (email) {
      newValues.email = email
    }
    if (session) {
      newValues.session = session
    }
    if (userId) {
      newValues.userId = userId
    }
    if (token) {
      newValues.token = token
      newValues.isRegistered = true
    }
    setContextValue((ctx) => ({ ...ctx, ...newValues }))
  }, [])
  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
    setContextValue((ctx) => ({ ...ctx, token, isRegistered: true, setUserData }))
  }, [])
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}
