export const OS_ENUM = {
  IOS: 'ios',
  WINDOWS: 'Windows',
  MAC: 'Mac',
  ANDROID: 'android',
  IPHONE: 'iPhone',
  CRI_OS: 'CriOS',
}

export const BROWSER_ENUM = {
  UNKNOWN: 'unknown',
  EDGE: 'Edge',
  FIREFOX: 'Firefox',
  CHROME: 'Chrome',
  SAFARI: 'Safari',
}

export const LOCAL_STORAGE_TOKEN_KEY = 'token'
export const SEARCH_PARAM_TOKEN_KEY = 'token'

export const SEARCH_PARAM_SUB_ACCOUNT_ID_KEY = 'subAccountId'
export const LOCAL_STORAGE_SUB_ACCOUNT_ID_KEY = 'subAccountId'
