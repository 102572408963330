import { MIN_GAP_BETWEEN_EVENTS } from '../constants'
import { identity as isTrue } from '../fp-utils'
import {
  InteractiveSchedulableItem,
  getObjectType,
  isQuizControl,
  parseAnswerIdFromObjectId,
  parseGroupIdFromObjectId,
  preloadMediaElement,
} from './helper-fns'
import {
  EVENT_TYPE,
  INTERACTIVE_ITEM_TYPE,
  OBJECT_TYPE,
  QUESTION_STATE_TYPE,
  QUIZ_MODE_TYPE,
  SCENE_ENUM,
} from './types'

// QuizBuilder implements logic to set up a Quiz adapter
// builder is separated from Quiz constructor because of extra logic (side effects)
// and dependencies that not related and not should be in Quiz
export class QuizBuilder {
  static buildQuizes(objects, slideData, defaultObjectsGroupedHashMap = {}, slideObjectsHashMap = {}) {
    // guards check for mandatory data and types
    if (!objects) return null
    if (!slideData) return null
    if (!Array.isArray(objects)) return null
    if (objects.length === 0) return null

    // probably we will have seceral quizes per slide, that's why quizes is an array
    // but mostly there is only one quiz [quiz]
    const { slide, startTime, endTime, slideId } = slideData
    const { answers: quizes = [], defaultObjects } = slide
    if (!Array.isArray(quizes)) return null
    if (quizes.length === 0) return null

    // to recalculate answer's objects positions
    let answerTopMostTop = 999999
    let answerTopMostLeft = 999999

    // iterate over quizes -> save quiz id and its adapter into hash map
    // then run a builder logic to set these quizes up
    const quizesMap = {}
    quizes.forEach(
      (quiz) => (quizesMap[quiz.id] = new Quiz(quiz, defaultObjectsGroupedHashMap[quiz.id], slideObjectsHashMap)),
    )
    objects.forEach((object) => {
      // first we need to find a Quiz instance by id, parse quiz id from object id
      const quizInstance = QuizBuilder.getQuizInstanceByObject(object, quizesMap)
      if (!quizInstance) return

      // find out time when this object should appear
      // mostly all objects from one interactive elements should appear at the same time
      // but this logic adds flexability to show different parts not at one time
      const startObjectTime = QuizBuilder.calcStartObjectTime(object, startTime, endTime)
      // set time into Quiz schedule -> then this schedule will be set into video schedule
      // add meta info about event
      quizInstance._setScheduleObjects(startObjectTime, {
        slideId,
        objectId: object.id,
        object,
        eventType: EVENT_TYPE.OBJECT_START,
        interactiveItemType: INTERACTIVE_ITEM_TYPE.QUIZ,
        interactiveItem: quizInstance,
        startTime: startObjectTime,
        endTime,
      })
      // set other parts of a quiz
      const objectType = getObjectType(object)
      switch (objectType) {
        case OBJECT_TYPE.UNKNOWN:
        case OBJECT_TYPE.OTHER:
          break

        case OBJECT_TYPE.QUIZ_BACKGROUND:
          quizInstance._setQuizBackground(object)
          break
        case OBJECT_TYPE.QUIZ_BUTTON_SEND:
          quizInstance._setQuizSendButton(object)
          break
        case OBJECT_TYPE.QUIZ_QUESTION:
          quizInstance._setQuizQuestion(object)
          break
        case OBJECT_TYPE.ANSWERS_CONTAINER:
          quizInstance._setAnswersContainer(object)
          break
        default:
          quizInstance._setAnswerData(object, objectType)
          answerTopMostTop = Math.min(object.top, answerTopMostTop)
          answerTopMostLeft = Math.min(object.left, answerTopMostLeft)
          break
      }
      // preload media
      preloadMediaElement(object)
    })

    const quizInstances = Object.values(quizesMap)
    // set start and end time after all objects have been set into the instance's schedule
    quizInstances.forEach((quiz) => {
      const startTime = quiz.getInstanceStartTime()
      quiz._setStartEndTime(startTime, slideData.endTime)
      quiz._setAnswersTopMostTop(answerTopMostTop)
      quiz._setAnswersTopMostLeft(answerTopMostLeft)
    })

    // preload default objects also
    defaultObjects.forEach(preloadMediaElement)
    // builder returns array of quizes
    // as said before mostly there will be only one element
    return quizInstances
  }
  static getQuizInstanceByObject({ id }, quizesMap) {
    const groupId = parseGroupIdFromObjectId(id)

    const quizInstance = quizesMap[groupId] ?? null
    return quizInstance
  }
  static calcStartObjectTime(object, slideStartTime, slideEndTime) {
    const { animation } = object
    if (!animation) return slideEndTime

    const { startScene, startTime } = animation
    const isStartTimeIsNumber = typeof startTime === 'number'
    if (isStartTimeIsNumber) return slideStartTime + startTime
    if (!isStartTimeIsNumber && startScene === SCENE_ENUM.END) return slideEndTime
    if (!isStartTimeIsNumber && startScene === SCENE_ENUM.START) return slideStartTime + MIN_GAP_BETWEEN_EVENTS
    return slideEndTime
  }
}

export class Quiz extends InteractiveSchedulableItem {
  #answers = new Set()
  #rightAnswers = new Set()
  #answerState = []
  constructor(quizMeta, defaultObjects = [], objectsHashMap = {}) {
    super(INTERACTIVE_ITEM_TYPE.QUIZ)
    this.id = quizMeta.id
    this.quizMode = QUIZ_MODE_TYPE.SINGLE_CHOICE
    this.meta = quizMeta
    this.objectsHashMap = objectsHashMap
    this.answersMap = {}
    this.question = null
    this.defaultObjects = defaultObjects
    this.viewObjects = {
      controlDefault: defaultObjects.filter((obj) => isQuizControl(obj.type) && !obj.checked)[0],
      controlChecked: defaultObjects.filter((obj) => isQuizControl(obj.type) && obj.checked)[0],

      quizBackground: null,
      quizSendButton: null,
      quizQuestion: null,

      answersContainer: null,
    }
    this.answersTopMostTop = 0
    this.answersTopMostLeft = 0
  }
  _setAnswerData(object, objectType) {
    const objectId = object.id
    const answerId = parseAnswerIdFromObjectId(objectId)

    const answerFromMap = this.answersMap[answerId] ?? { answerId }
    switch (objectType) {
      case OBJECT_TYPE.ANSWER_BACKGROUND:
        answerFromMap.background = object
        break
      case OBJECT_TYPE.ANSWER_TEXT:
        answerFromMap.text = object
        break
      case OBJECT_TYPE.ANSWER_CONTROL_CHECKBOX:
      case OBJECT_TYPE.ANSWER_CONTROL_RADIO: {
        answerFromMap.control = object
        this.#answers.add(answerId)
        if (object.question?.state === QUESTION_STATE_TYPE.CHECKED) {
          this.#rightAnswers.add(answerId)
          this.quizMode = this.#rightAnswers.size > 1 ? QUIZ_MODE_TYPE.MULTI_CHOICE : QUIZ_MODE_TYPE.SINGLE_CHOICE
        }
        break
      }
      default:
        break
    }
    this.answersMap[answerId] = answerFromMap
  }
  _setQuizBackground(object) {
    this.viewObjects.quizBackground = object
  }
  _setQuizQuestion(object) {
    this.question = object
    this.viewObjects.quizQuestion = object
  }
  _setQuizSendButton(object) {
    this.viewObjects.quizSendButton = object
  }

  _setAnswersContainer(object) {
    this.viewObjects.answersContainer = object
  }
  _setAnswersTopMostTop(value) {
    this.answersTopMostTop = value
  }
  _setAnswersTopMostLeft(value) {
    this.answersTopMostLeft = value
  }

  get answerList() {
    return [...this.#answers]
  }
  get rightAnswersList() {
    return [...this.#rightAnswers]
  }
  get answerState() {
    return [...this.#answerState]
  }

  setAnswerState(answers) {
    this.#answerState = [...answers]
  }
  isAnswerRight(answerId) {
    return this.#rightAnswers.has(answerId)
  }
  checkAnswers(answerIds) {
    if (!Array.isArray(answerIds)) return false
    const { rightAnswersList } = this
    if (answerIds.length !== rightAnswersList.length) return false
    const isAnswerRight = this.isAnswerRight.bind(this)

    const answeredResults = answerIds.map(isAnswerRight)
    this.setAnswerState(answerIds)
    return answeredResults.every(isTrue)
  }

  getOnCorrect() {
    return this.meta.onCorrect
  }
  getOnWrong() {
    return this.meta.onWrong
  }
}
