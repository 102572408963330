import { AnalyticsService } from '../../services/analytics.service'

export const SESSION_ID_LOCAL_STORAGE_KEY = 'quizSessionId'
export const SESSION_VIDEO_ID_LOCAL_STORAGE_KEY = 'sessionVideoId'
export class AnalyticsCollector {
  #scorm = null
  #prevSlideId = null
  #slides = []
  #videoSchedule = null
  #videoElement = null
  #currentSlideId = null
  #durationWatchedStartTimestamp = null
  #durationWatchedSet = new Set()

  #amountOfClicks = 0
  #id = null
  #videoId = null
  #url = null
  #studentName = null
  #studentEmail = null
  constructor(scorm) {
    if (!scorm) return null
    this.#scorm = scorm
    this.isInitialized = false
    this.isFinished = false
    this.hasQuiz = false
  }
  get metrics() {
    if (!this.isInitialized) return null
    return {
      _id: this.#id,
      hasQuiz: this.hasQuiz,
      videoId: this.#videoId,
      amountOfClicks: this.#amountOfClicks,
      slides: this.#slides,
      url: this.#url,
      studentName: this.#studentName,
      studentEmail: this.#studentEmail,
    }
  }
  async init({ videoSchedule, videoElement, studentName, studentEmail }) {
    const videoId = window.videoData?.id ?? videoSchedule.videoId
    const url = window.location !== window.parent.location ? document.referrer : document.location.href
    this.#url = url
    this.#videoSchedule = videoSchedule
    this.#videoElement = videoElement
    this.#studentName = studentName
    this.#studentEmail = studentEmail
    if (this.#scorm.enabled) {
      this.#studentName = this.#scorm.studentName
      this.#studentEmail = this.#scorm.studentEmail
    }
    // as some values may reset, recalculate anew
    this.isFinished = false
    // if it's different video then the one user was watched then create new
    const prevSessionVideoId = localStorage.getItem(SESSION_VIDEO_ID_LOCAL_STORAGE_KEY)
    if (prevSessionVideoId !== videoId) {
      localStorage.clear()
      this.#id = undefined
      this.#videoId = videoId
      await this.#updateMetrics()
    } else {
      const prevSessionId = localStorage.getItem(SESSION_ID_LOCAL_STORAGE_KEY)
      this.#id = prevSessionId
      this.#videoId = prevSessionVideoId
    }
    this.isInitialized = true
  }

  async #updateMetrics() {
    if (!this.isInitialized) return
    const metrics = this.metrics
    const video = this.#videoSchedule.videoData
    if (!metrics) return
    if (!video) return

    // axios throws unsafe header for keepalive
    const result = await AnalyticsService.getUpdatedMetrics(metrics)

    // if api created new session then we update stored data
    if (result.createNew === true) {
      this.#id = result.id
      localStorage.setItem(SESSION_ID_LOCAL_STORAGE_KEY, result.id)
      localStorage.setItem(SESSION_VIDEO_ID_LOCAL_STORAGE_KEY, this.#videoId)
    }
    if (result.scorm) {
      this.#scorm.score = result.scorm.score
      this.#scorm.progress = result.scorm.progress
    }
    if (this.#slides.length && video.slides?.length) {
      this.#scorm.completed = this.isFinished ?? false
    }
    if (this.#scorm.completed) this.#scorm.close()
  }

  #setSlideDurationWatched(slide) {
    if (typeof slide === 'number') slide = this.#slides.find(({ id }) => id === slide)
    if (!slide) return
    const slideWatchedDurations = this.#durationWatchedSet[slide.id]
    if (!slideWatchedDurations) return
    slide.durationWatched = slideWatchedDurations.reduce((a, b) => a + b, 0)
  }

  #updateDurationSet(time) {
    if (!this.#currentSlideId) return
    const current = this.#durationWatchedSet[this.#currentSlideId]
    if (!current) this.#durationWatchedSet[this.#currentSlideId] = [time]
    else current.push(time)
  }

  setCurrentSlideId(slideId, shouldReset) {
    if (!shouldReset) this.stopTimer()
    this.startTimer()
    if (slideId === undefined || slideId === null) return
    if (slideId === this.#prevSlideId) return
    this.#currentSlideId = slideId
    this.setQuizSlides()
    this.#setSlideDurationWatched(slideId)
  }

  startTimer() {
    this.#durationWatchedStartTimestamp = Date.now()
  }

  stopTimer() {
    const elapsedTime = (Date.now() - this.#durationWatchedStartTimestamp) / 1000
    this.#updateDurationSet(elapsedTime)
  }

  setQuizSlides() {
    if (!this.#currentSlideId) return
    this.isFinished = false
    const slideMetrics = this.#slides.find(({ id }) => id === this.#currentSlideId)
    this.#prevSlideId = this.#currentSlideId
    if (!slideMetrics) {
      const slideData = this.#videoSchedule.slidesHashMap[this.#currentSlideId]
      const slideMetaData = this.#videoSchedule.slidesMetaHashMap[this.#currentSlideId]
      this.#slides.push({
        id: this.#currentSlideId,
        elementClicked: false,
        duration: slideData.duration,
        question: slideMetaData.interactiveElementsOfSlide.quizes[0]?.question.text,
      })
    }
  }

  setClick() {
    const metricToUpdate = this.#slides.find(({ id }) => id === this.#currentSlideId)
    if (!metricToUpdate) return
    metricToUpdate.elementClicked = true
    this.#amountOfClicks++
    this.#updateMetrics()
  }

  // call in in the Quiz component -> send quiz id
  setAnswerClick(userAnswers, result, quizId) {
    // should add some private methods to find it in better way
    const question = this.#videoSchedule.slidesMetaHashMap[this.#currentSlideId].interactiveElementsOfSlide.quizes.find(
      ({ id }) => id === quizId,
    ).question.text
    // select and narrow user answers to string values divided by coma
    // const selectedAnswers = // get it from params

    // get slide metrics via this.#slidesMap[id]
    this.#slides.forEach((slide) => {
      if (this.#currentSlideId === slide.id) {
        slide.answers = userAnswers
        slide.isAnswerCorrect = result
        if (question.text) {
          slide.question = question
        }
      }
    })

    this.#scorm.interaction({
      id: this.#currentSlideId,
      description: question,
      // NOTE: now it operates with true/false values (correct/incorrect answer), but may be we should retrieve it from quiz? (text representation, not answer identifier)
      userResponse: String(result),
      result,
      correctResponse: 'true',
    })
    this.#updateMetrics()
  }

  collectWhenFinished() {
    // mark that video is ended which means we need to calculate results
    this.isFinished = true
    this.saveBeforeUnload()
  }

  setHasQuiz() {
    this.hasQuiz = true
  }

  saveBeforeUnload() {
    // update slides durations
    for (let index = 0; index < this.#slides.length; index++) {
      const slide = this.#slides[index]
      this.#setSlideDurationWatched(slide)
    }
    this.#updateMetrics()
  }
}
