import { motion } from 'framer-motion'
import { noop } from '../../../utils'
import { useAnalyticContext, usePlayerContext, useScheduledPlayerContext } from '../../../hooks'
import { transitionTemplate } from '../../../constants/constants'

export const InteractiveButton = (props) => {
  const { interactiveItem, onInteractivityFinished, slideMeta } = props
  const { rewindOnSlideHandler } = useScheduledPlayerContext()
  const { playerBounding } = usePlayerContext()
  const { analyticCollector } = useAnalyticContext()

  const onClickHandler = () => {
    analyticCollector.setClick()
    let { slide: slideId } = interactiveItem.getActionStrategy()
    if (!slideId) slideId = slideMeta.nextSlideId
    if (slideId) rewindOnSlideHandler(slideId)
    onInteractivityFinished(noop)
  }

  const { object, getElementStyles } = interactiveItem

  return (
    <motion.button
      {...transitionTemplate}
      style={{
        // display: 'block',
        backgroundColor: 'transparent',
        border: 'none',
        ...getElementStyles(playerBounding, object),
      }}
      onClick={onClickHandler}
    >
      <span className="sr-only">INTERACTIVE BUTTON</span>
    </motion.button>
  )
}
