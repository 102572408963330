import { useClientContext } from '../hooks'
import { PLAYER_VIEW_HTML_ID } from '../constants/constants'
/*
  Stands for:
    - makes layout and css styles
*/

export const InteractivePlayerView = (props) => {
  const { video, controls, interactiveObjects, spinner, playPauseOverlay } = props

  const { playerViewWrapperRef, isFullScreen } = useClientContext()

  return (
    <div
      className="player-view"
      id={PLAYER_VIEW_HTML_ID}
      ref={playerViewWrapperRef}
      style={{ display: isFullScreen ? 'flex' : 'block' }}
    >
      {spinner}
      {playPauseOverlay}
      <div className="player-view--video">{video}</div>
      <div className="player-view--interactivity">{interactiveObjects}</div>
      <div className="player-view--controls">{controls}</div>
    </div>
  )
}
