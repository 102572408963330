import { useEffect, useState } from 'react'
import { VideoDataContextProvider } from '../../contexts'
import { InteractiveController } from './InteractiveController'
import { VideoScheduler } from '../../utils/video-helpers/video-helper'
import { useClientContext, usePlayerContext } from '../../hooks'

/*
  Stands for:
    - creates an instance of VideoScheduler
    - gets signal when all interactivity prepearing process are finished
    - unpacks some data from video schedule and pass it as props into other components
    - signals at the top level about readiness
*/
export const InteractivePlayerSchedule = (props) => {
  const { children, videoElement, video, videoId } = props

  const [videoSchedule, setVideoSchedule] = useState(null)
  const isVideoScheduleReady = !!videoSchedule

  const { rewindOnTimeHandler } = usePlayerContext()
  const { isIOS } = useClientContext()

  const [isActionsScheduled, setIsActionsScheduled] = useState(false)

  useEffect(() => {
    if (!video) return
    if (!videoElement) return
    // need real video duration, so we create a Scheduler only when metadata is loaded
    videoElement.onloadedmetadata = () => {
      setVideoSchedule(new VideoScheduler({ video, videoId, videoElement }))
      if (!isIOS) return
      const url = new URL(document.location.href)
      let time = +url.searchParams.get('time')
      if (time) {
        setTimeout(() => {
          videoElement.currentTime = time
          // for remove glitch when set on slide end with interactive elements
          rewindOnTimeHandler(time + 0.0001, false)
        }, 500)
      }
    }
  }, [video, videoElement, rewindOnTimeHandler])

  return (
    <VideoDataContextProvider videoSchedule={videoSchedule} video={video}>
      <InteractiveController
        isVideoScheduleReady={isVideoScheduleReady}
        videoSchedule={videoSchedule}
        isActionsScheduled={isActionsScheduled}
        setIsActionsScheduled={setIsActionsScheduled}
        videoElement={videoElement}
      >
        {children}
      </InteractiveController>
    </VideoDataContextProvider>
  )
}
