import React from 'react'
import Icon, { IconsENUM } from '../Icons/Icon'
import { AnimatePresence, motion } from 'framer-motion'

import './playPauseOverlay.less'

const PlayPauseOverlay = (props) => {
  const { isPaused, videoElement, bounding } = props
  const { ratioX } = bounding ?? {}

  const template = {
    initial: { opacity: videoElement ? 1 : 0, scale: ratioX / 2, translateX: '-50%', translateY: '-50%' },
    animate: { opacity: 0, scale: ratioX, translateX: '-50%', translateY: '-50%' },
    exit: { opacity: 0, scale: ratioX, translateX: '-50%', translateY: '-50%' },
    transition: { duration: 0.7 },
  }

  return (
    <AnimatePresence>
      <motion.div
        {...template}
        className="play-pause-overlay"
        key={'play-pause-overlay-' + !videoElement ? isPaused : videoElement.paused}
        style={{ width: 70, height: 70 }}
      >
        <Icon name={!isPaused ? IconsENUM.play_rounded : IconsENUM.pause_rounded} />
      </motion.div>
    </AnimatePresence>
  )
}

export default PlayPauseOverlay
