import { QUIZ_MODE_TYPE } from '../../../utils/video-helpers/types'
import { QuizAnswer } from './QuizAnswer'
import './quiz-styles.less'
import { usePlayerContext } from '../../../hooks'

export const QuizAnswers = (props) => {
  const { quizData, chosenAnswers, setChosenAnswers, quizQuestionText } = props
  const {
    viewObjects: { answersContainer, controlDefault, controlChecked },
    answerList,
    answersMap,
    quizMode,
    answerState,
    answersTopMostTop,
    answersTopMostLeft,
  } = quizData

  const { playerBounding } = usePlayerContext()

  const isCheckbox = quizMode === QUIZ_MODE_TYPE.MULTI_CHOICE

  const createOnAnswerChooseHandler = (answerId) => (event) => {
    const {
      target: { checked },
    } = event
    setChosenAnswers((checkedAnswers) => ({ ...(isCheckbox ? checkedAnswers : {}), [answerId]: checked ?? false }))
    quizData.setAnswerState([])
  }
  return (
    <fieldset className="quiz-fieldset" style={quizData.getElementStyles(playerBounding, answersContainer)}>
      <legend className="sr-only">{quizQuestionText}</legend>
      {answerList.map((answerId, index) => {
        const answerData = answersMap[answerId]
        if (!answerData) return null
        const { background, control, text: textData } = answerData
        const inputType = isCheckbox ? 'checkbox' : 'radio'
        const isChecked = answerState.includes(answerId) || !!chosenAnswers[answerId]
        return (
          <QuizAnswer
            key={answerId}
            answerId={answerId}
            index={index}
            backgroundObject={background}
            topRecalcValue={answersTopMostTop}
            leftRecalcValue={answersTopMostLeft}
            controlViewData={{ ...control, src: isChecked ? controlChecked.src : controlDefault.src }}
            textData={textData}
            inputType={inputType}
            isChecked={isChecked}
            isCheckbox={isCheckbox}
            getElementStyles={quizData.getElementStyles}
            onAnswerChooseHandler={createOnAnswerChooseHandler(answerId)}
          />
        )
      })}
    </fieldset>
  )
}
