export const EVENT_TYPE = {
  VIDEO_START: 'video-start',
  VIDEO_END: 'video-end',
  SLIDE_START: 'slide-start',
  SLIDE_END: 'slide-end',
  OBJECT_START: 'object-start',
  OBJECT_END: 'object-end',
  OTHER: 'other',
}

export const INTERACTIVE_ITEM_TYPE = {
  BUTTON: 'button',
  LINK: 'link',
  QUIZ: 'quiz',
}

export const MARK_TYPE = {
  INTERACTIVE: 'interactive',
  SLIDE: 'slide',
}

export const SCENE_ENUM = {
  START: 'start',
  END: 'end',
}

export const INTERACTIVE_ACTION_TYPE = {
  LINK_ACTION: 'link',
  SLIDE_ACTION: 'slide',
}

export const QUESTION_SUFIX_QUIZ_ID = '-question'
export const BACKGROUND_SUFIX_QUIZ_ID = '-background'
export const BUTTON_SEND_SUFIX_QUIZ_ID = '-button_send'

export const ANSWERS_CONTAINER_SUFIX_QUIZ_ID = '-answers_container'
export const ANSWERS_GROUP_SUFIX_QUIZ_ID = '-answers_group'

export const ANSWER_SUB_OBJECT_SUFIX_QUIZ_ID = '-answer'
export const ANSWER_CHECKBOX_SUFIX_QUIZ_ID = '-answer_checkbox'
export const ANSWER_RADIO_SUFIX_QUIZ_ID = '-answer_radio'
export const ANSWER_BACKGROUND_SUFIX_QUIZ_ID = '-answer_background'
export const ANSWER_TEXT_SUFIX_QUIZ_ID = '-answer_text'

export const OBJECT_TYPE = {
  QUIZ_QUESTION: QUESTION_SUFIX_QUIZ_ID,
  QUIZ_BACKGROUND: BACKGROUND_SUFIX_QUIZ_ID,
  QUIZ_BUTTON_SEND: BUTTON_SEND_SUFIX_QUIZ_ID,

  ANSWERS_CONTAINER: ANSWERS_CONTAINER_SUFIX_QUIZ_ID,

  ANSWER_TEXT: ANSWER_TEXT_SUFIX_QUIZ_ID,
  ANSWER_BACKGROUND: ANSWER_BACKGROUND_SUFIX_QUIZ_ID,
  ANSWER_CONTROL_CHECKBOX: ANSWER_CHECKBOX_SUFIX_QUIZ_ID,
  ANSWER_CONTROL_RADIO: ANSWER_RADIO_SUFIX_QUIZ_ID,

  OTHER: 'other', // for buttons and links should add something
  UNKNOWN: 'unknown',
}

export const QUIZ_MODE_TYPE = {
  SINGLE_CHOICE: 'single-choice',
  MULTI_CHOICE: 'multi-choice',
}

export const QUESTION_STATE_TYPE = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
}

export const QUIZ_CONTROL_TYPES = {
  CHECKBOX: 'answer_checkbox',
  RADIO: 'answer_radio',
}
