export * from './theme-config'
export * from './player-config'
export * from './ui-config'
export * from './constant-enum'
export * from './api-config'

export const ENVS = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_IS_DEBUG: process.env.REACT_APP_IS_DEBUG === 'true',
}
