import { API_URLS } from '../configs'
import { request } from '../utils'

export const fetchVideo = async ({ videoId, videoData }, token) => {
  if (videoData) videoId = videoData.id + '?s=' + videoData.signature

  let url
  if (token) {
    url = API_URLS.VIDEO_PRIVATE.createURL(videoId)
  } else {
    url = API_URLS.VIDEO_PUBLIC.createURL(videoId)
  }
  // error processing is inside request fn -> show a toast with error message
  const response = await request({ url })
  return response ? response : null
}
