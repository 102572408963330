import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { MIN_GAP_BETWEEN_EVENTS, noop } from '../../utils'
import { Quiz } from './Quiz/Quiz'
import { InteractiveLink } from './Links/InteractiveLink'
import { InteractiveButton } from './Buttons/InteractiveButton'
import { transitionTemplate } from '../../constants/constants'
import { CONTROLS_VISIBILITY_MODE_TYPE } from '../Controls/types'
import { createIsTimeKeyInRangeWithCurrentTime, createSimpleWatcher } from '../../utils/interactivity-helpers'
import { getCurrentSubtitles } from '../../utils/video-helpers/helper-fns'
import { EVENT_TYPE, INTERACTIVE_ITEM_TYPE } from '../../utils/video-helpers/types'
import { useAnalyticContext, usePlayerContext, useVideoDataContext } from '../../hooks'

/*
  Stands for:
    - sets current interactive element
    - sets current object
    - figure out what components to render
    - starts additional watcher that loop over slide events and set interactive items into state (if there is an interactivity)
    - controll interactivity is finished
*/
export const Slide = (props) => {
  const { slideMeta, slideEvent } = props
  const {
    videoElement,
    forceChangedTime,
    isControlSetToHover,
    pauseHandler,
    subtitlesHandler,
    controlSetToHoverHandler,
    controlVisibilityModeHandler,
  } = usePlayerContext()
  const { videoSchedule } = useVideoDataContext()
  const { analyticCollector } = useAnalyticContext()
  const [, setInteractiveItems] = useState([])
  const [watcherCallbackIds, setWatcherCallbackIds] = useState([])

  const interactiveItemsRef = useRef(new Set())
  const shouldWatcherRunRef = useRef(false)
  const interactivity = useRef({
    isFinished: false,
    next: noop,
    events: null,
    eventsMeta: null,
    shouldPlayOnSlideEnd: false,
  })

  const createOnFinishInteractivity =
    () =>
    (next = noop, shouldPlayOnSlideEnd = false) => {
      interactivity.current = {
        ...interactivity.current,
        next,
        isFinished: true,
        shouldPlayOnSlideEnd,
      }
    }

  const removeCallbacks = () => {
    watcherCallbackIds.forEach((id) => {
      window.cancelAnimationFrame(id)
    })
  }
  useEffect(() => {
    shouldWatcherRunRef.current = true
    return () => {
      shouldWatcherRunRef.current = false
      removeCallbacks()
    }
  }, [])

  useEffect(() => {
    if (!videoElement) return
    if (!videoSchedule) return
    removeCallbacks()
    const shouldWatcherRun = () => shouldWatcherRunRef.current
    /**
     * Use watcherCallback fn if you need effects triggered by changed videoElement.currentTime
     */
    const watcherCallback = (_, currentTime) => {
      // get and set subtitles
      if (videoSchedule.hasSubtitles) {
        const subtitles = getCurrentSubtitles(videoElement, currentTime)
        subtitlesHandler(subtitles)
      }

      // hide controls on slide end with interactivity
      const isInTimeRange = createIsTimeKeyInRangeWithCurrentTime(currentTime)

      if (isInTimeRange(slideMeta.startTime + MIN_GAP_BETWEEN_EVENTS) && isControlSetToHover) {
        controlVisibilityModeHandler(CONTROLS_VISIBILITY_MODE_TYPE.HOVER)
        controlSetToHoverHandler(false)
      }

      // interactivity elements section
      if (!videoSchedule.hasVideoInteractivity) return
      if (!slideMeta.hasInteractivity) return

      let { events, eventsMeta } = interactivity.current
      if (!events) {
        const [slideEvents, eventsMetaMap] = slideMeta.interactiveItemsSchedule
        interactivity.current = {
          ...interactivity.current,
          events: slideEvents,
          eventsMeta: eventsMetaMap,
        }
        events = slideEvents
        eventsMeta = eventsMetaMap
      }
      const isTimeInRange = createIsTimeKeyInRangeWithCurrentTime(currentTime)
      const interactiveItemsToStart = events.reduce((acc, eventTime) => {
        const eventsOnThisTime = eventsMeta[eventTime]
        eventsOnThisTime.forEach((meta) => {
          const { item, startTime, endTime } = meta
          const isQuiz = item.itemType === INTERACTIVE_ITEM_TYPE.QUIZ
          if (isInTimeRange(slideMeta.endTime) && isQuiz) {
            acc.add(item)
            videoElement.currentTime = slideMeta.endTime
            pauseHandler()
            controlVisibilityModeHandler(CONTROLS_VISIBILITY_MODE_TYPE.HIDDEN)
          } else if (isTimeInRange(slideMeta.endTime) && !interactivity.current.isFinished) {
            videoElement.currentTime = slideMeta.endTime
            pauseHandler()
            controlVisibilityModeHandler(CONTROLS_VISIBILITY_MODE_TYPE.HIDDEN)
          } else if (isTimeInRange(startTime) || (currentTime >= startTime && currentTime <= endTime)) {
            acc.add(item)
          } else if (!isTimeInRange(slideMeta.endTime) && isQuiz) {
            // way to remove quiz if forceChangedTime was triggered in all cases when there is no slide end currently
            interactiveItemsRef.current.delete(item)
            setInteractiveItems((items) => items.filter((current) => current !== item))
          } else if (isTimeInRange(endTime)) {
            // way to handle other interactivities with no hiding until end of slide.
            return
          } else if (interactiveItemsRef.current.has(item)) {
            // other ways we should remove them from slide when forceChangeTime was triggered
            interactiveItemsRef.current.delete(item)
            setInteractiveItems((items) => items.filter((current) => current !== item))
          }
        })
        return acc
      }, new Set())
      interactiveItemsToStart.forEach((item) => {
        if (interactiveItemsRef.current.has(item)) return
        interactiveItemsRef.current.add(item)
        setInteractiveItems((items) => [...items, item])
      })
    }
    const rafWatcherCallback = createSimpleWatcher({
      shouldWatcherRun,
      videoElement,
      schedule: videoSchedule,
      callback: watcherCallback,
    })
    const id = window.requestAnimationFrame(rafWatcherCallback)
    setWatcherCallbackIds((prev) => [...prev, id])
  }, [videoSchedule, forceChangedTime])

  // We need to recall RAF when time was changed externally (timeline rewind, etc.) and slide also was changed
  // Effect to rerender interactive elements
  // useEffect(() => {
  //   if (!videoElement) return
  //   if (!forceChangedTime) return // This can be removed to show interactive in the currentTime=0 (start of video)
  //   videoElement.currentTime += 0.0001
  // }, [forceChangedTime])

  useEffect(() => {
    if (!slideEvent) return
    if (!videoSchedule.hasVideoInteractivity) return
    if (!slideMeta.hasInteractivity) return
    const isSlideEnd = slideEvent.eventType === EVENT_TYPE.SLIDE_END
    if (isSlideEnd) analyticCollector.saveBeforeUnload()
    const { next, shouldPlayOnSlideEnd } = interactivity.current
    next()
    interactivity.current.next = noop
    // Set isFinished to false when rewinded for example from quiz to the same slide.
    // But if shouldPlayOnSlideEnd presence avoid cases when links was pressed while playing slide.
    // In this case we should continue play in the end of the slide
    if (!shouldPlayOnSlideEnd) interactivity.current.isFinished = false
  }, [slideEvent, interactivity.current.isFinished])

  const interactiveItemsToShow = [...interactiveItemsRef.current]

  return (
    <motion.div {...transitionTemplate}>
      <AnimatePresence>
        {interactiveItemsToShow.map((interactiveItem) => {
          const { itemType, id } = interactiveItem
          switch (itemType) {
            case INTERACTIVE_ITEM_TYPE.BUTTON:
              return (
                <InteractiveButton
                  onInteractivityFinished={createOnFinishInteractivity(id, itemType)}
                  key={id}
                  slideMeta={slideMeta}
                  interactiveItem={interactiveItem}
                />
              )
            case INTERACTIVE_ITEM_TYPE.LINK:
              return (
                <InteractiveLink
                  onInteractivityFinished={createOnFinishInteractivity(id, itemType)}
                  key={id}
                  interactiveItem={interactiveItem}
                />
              )
            case INTERACTIVE_ITEM_TYPE.QUIZ:
              return (
                <Quiz
                  onInteractivityFinished={createOnFinishInteractivity(id, itemType)}
                  key={id}
                  interactiveItem={interactiveItem}
                />
              )
            default:
              return null
          }
        })}
      </AnimatePresence>
    </motion.div>
  )
}
