// constants should be all caps, snake_case
export const DEFAULT_VOLUME = 30
export const IOS_PLAYER_HEIGHT = 'calc(100vh - 80px)'
export const INTERACTIVE_PLAYER_HTML_ID = 'interactive-player'
export const PLAYER_VIEW_HTML_ID = 'player-view'
export const ARIA_LIVE_REGION_HTML_ID = 'aria-live-region'
export const TRANSITION_TIME = 0.4
export const transitionTemplate = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: TRANSITION_TIME },
}
