import { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { getBoundingRectForElement, noop } from '../utils'
import { BROWSER_ENUM, DEFAULT_PLAYBACK_RATE, DEFAULT_VOLUME, IOS_PLAYER_HEIGHT } from '../configs'
import { CONTROLS_VISIBILITY_MODE_TYPE } from '../components/Controls/types'
import { useClientContext } from '../hooks'

const defaultPlayerContextValue = {
  isCanvasMode: false, // should try instead of video tag use a canvas https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Manipulating_video_using_canvas
  isPaused: true,
  isVideoFinished: false,
  isMuted: false,
  isSubtitlesVisible: true,
  isInFocus: true,
  isVideoReady: false,
  isPlayDisabled: false,
  isControlSetToHover: false,

  videoElement: null,
  volume: DEFAULT_VOLUME,
  playerBounding: {},
  playbackRate: DEFAULT_PLAYBACK_RATE,
  forceChangedTime: 0, // need to quickly update timeline slider state, while video.ontimeupdate is still not thrown
  currentSubtitles: '',
  controlVisibilityMode: CONTROLS_VISIBILITY_MODE_TYPE.VISIBLE,

  playHandler: noop,
  pauseHandler: noop,
  rewindOnTimeHandler: noop,
  volumeChangeHandler: noop,
  volumeMuteHandler: noop,
  playbackChangeHandler: noop,
  subtitlesVisibilityHandler: noop,
  extendPlayerContextWith: noop,
  videoReadyHandler: noop,
  videoFinishedHandler: noop,
  playDisabledHandler: noop,
  subtitlesHandler: noop,
  controlVisibilityModeHandler: noop,
  controlSetToHoverHandler: noop,
  fullScreenHandler: noop,
}
export const PlayerContext = createContext(defaultPlayerContextValue)

/*
  Stands for:
    - provides videElement deeper
    - set pause if user change focus on another tab or window
    - provides wrapped with interactivity logic handlers for player (play, pause, rewind etc), some handlers with business logic are passed from outside
*/
// TODO -> handleFullScreen
export const PlayerContextProvider = (props) => {
  const { children, videoElement } = props
  const [contextValue, setContextValue] = useState(defaultPlayerContextValue)
  const playTimerRef = useRef(null)
  const { isIOS, isIOSFullScreen, playerViewWrapperRef, browser, isFullScreenHandler, isDebug } = useClientContext()

  const playHandler = useCallback(
    (timeShift = 0) => {
      if (isDebug) console.log('RUN PLAY HANDLER', { paused: videoElement.paused, timeShift })
      if (!videoElement) return
      // weired condition, but on timeShift=0 it sounds echo while playing video
      if (timeShift) videoElement.currentTime += timeShift
      if (videoElement.paused) videoElement.play()
      setContextValue((ctx) => ({
        ...ctx,
        isPaused: false,
        isVideoFinished: false,
        isPlayDisabled: false,
        controlVisibilityMode: CONTROLS_VISIBILITY_MODE_TYPE.HOVER,
      }))
    },
    [videoElement],
  )
  const pauseHandler = useCallback(() => {
    if (isDebug) console.log('RUN PAUSE HANDLER')
    if (!videoElement) return
    videoElement.pause()
    setContextValue((ctx) => ({ ...ctx, isPaused: true, controlVisibilityMode: CONTROLS_VISIBILITY_MODE_TYPE.VISIBLE }))
  }, [videoElement])
  const rewindOnTimeHandler = useCallback(
    (time, shouldPlay = true) => {
      if (isDebug) console.log('RUN REWIND ON TIME HANDLER', { time, shouldPlay })
      if (!videoElement) return
      const timeValue = +time
      if (Number.isNaN(timeValue)) return
      videoElement.currentTime = timeValue
      const isFinished = videoElement.currentTime === videoElement.duration
      setContextValue((ctx) => ({ ...ctx, forceChangedTime: timeValue, isVideoFinished: isFinished }))

      if (shouldPlay) {
        playTimerRef.current = setTimeout(playHandler, 500)
      } else {
        pauseHandler()
      }
    },
    [videoElement],
  )
  const volumeChangeHandler = useCallback(
    (value) => {
      const volumeValueNumber = +value
      if (!videoElement) return
      if (Number.isNaN(volumeValueNumber)) return

      const newVolumeValue = volumeValueNumber / 100
      videoElement.volume = newVolumeValue
      setContextValue((ctx) => ({ ...ctx, volume: newVolumeValue }))
    },
    [videoElement],
  )
  const volumeMuteHandler = useCallback(
    (data) => {
      setContextValue((ctx) => ({ ...ctx, isMuted: data }))
      videoElement.muted = data
    },
    [videoElement],
  )
  const playbackChangeHandler = useCallback(
    (rate) => {
      if (!videoElement) return
      if (rate === 'Normal') rate = 1
      setContextValue((ctx) => ({ ...ctx, playbackRate: +rate }))
      videoElement.playbackRate = +rate
    },
    [videoElement],
  )
  const subtitlesVisibilityHandler = useCallback(() => {
    if (!videoElement) return
    setContextValue((ctx) => ({ ...ctx, isSubtitlesVisible: !ctx.isSubtitlesVisible }))
  }, [videoElement])

  const videoReadyHandler = useCallback(
    (isReady) => {
      if (!videoElement) return
      setContextValue((ctx) => ({ ...ctx, isVideoReady: isReady }))
    },
    [videoElement],
  )
  const videoFinishedHandler = useCallback(
    (value) => {
      if (!videoElement) return
      setContextValue((ctx) => ({ ...ctx, isVideoFinished: value }))
    },
    [videoElement],
  )
  const playDisabledHandler = useCallback(
    (value) => {
      if (!videoElement) return
      setContextValue((ctx) => ({ ...ctx, isPlayDisabled: value }))
    },
    [videoElement],
  )
  const subtitlesHandler = useCallback(
    (value) => {
      if (!videoElement) return
      setContextValue((ctx) => ({ ...ctx, currentSubtitles: value }))
    },
    [videoElement],
  )
  const controlVisibilityModeHandler = useCallback(
    (value) => {
      if (!videoElement) return
      setContextValue((ctx) => ({ ...ctx, controlVisibilityMode: value }))
    },
    [videoElement],
  )
  const controlSetToHoverHandler = useCallback(
    (value) => {
      if (!videoElement) return
      setContextValue((ctx) => ({ ...ctx, isControlSetToHover: value }))
    },
    [videoElement],
  )
  const fullScreenHandler = useCallback(() => {
    if (isIOS && isIOSFullScreen) return
    if (isIOS) {
      const url = new URL(document.location.href)
      url.searchParams.set('noopen', 'true')
      if (videoElement.currentTime) url.searchParams.set('time', videoElement.currentTime.toString())
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = url.toString()
      link.click()
      return
    }

    if (!playerViewWrapperRef?.current) return
    const { current: element } = playerViewWrapperRef
    if (document.fullscreenElement || document.webkitFullscreenElement) {
      if (document?.exitFullscreen) document.exitFullscreen()
      if (document?.webkitCancelFullScreen) document.webkitCancelFullScreen() // Safari v15
      isFullScreenHandler(false)
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen()
      }
      isFullScreenHandler(true)
    }
  }, [videoElement, playerViewWrapperRef?.current])

  useEffect(() => {
    // init and re-init callbacks
    let currentContextValue = contextValue

    if (playHandler !== currentContextValue.playHandler) {
      currentContextValue = { ...currentContextValue, playHandler }
    }
    if (pauseHandler !== currentContextValue.pauseHandler) {
      currentContextValue = { ...currentContextValue, pauseHandler }
    }
    if (rewindOnTimeHandler !== currentContextValue.rewindOnTimeHandler) {
      currentContextValue = { ...currentContextValue, rewindOnTimeHandler }
    }
    if (volumeChangeHandler !== currentContextValue.volumeChangeHandler) {
      currentContextValue = { ...currentContextValue, volumeChangeHandler }
    }
    if (volumeMuteHandler !== currentContextValue.volumeMuteHandler) {
      currentContextValue = { ...currentContextValue, volumeMuteHandler }
    }
    if (playbackChangeHandler !== currentContextValue.playbackChangeHandler) {
      currentContextValue = { ...currentContextValue, playbackChangeHandler }
    }
    if (subtitlesVisibilityHandler !== currentContextValue.subtitlesVisibilityHandler) {
      currentContextValue = { ...currentContextValue, subtitlesVisibilityHandler }
    }
    if (videoReadyHandler !== currentContextValue.videoReadyHandler) {
      currentContextValue = { ...currentContextValue, videoReadyHandler }
    }
    if (videoFinishedHandler !== currentContextValue.videoFinishedHandler) {
      currentContextValue = { ...currentContextValue, videoFinishedHandler }
    }
    if (playDisabledHandler !== currentContextValue.playDisabledHandler) {
      currentContextValue = { ...currentContextValue, playDisabledHandler }
    }
    if (subtitlesHandler !== currentContextValue.subtitlesHandler) {
      currentContextValue = { ...currentContextValue, subtitlesHandler }
    }
    if (controlVisibilityModeHandler !== currentContextValue.controlVisibilityModeHandler) {
      currentContextValue = { ...currentContextValue, controlVisibilityModeHandler }
    }
    if (controlSetToHoverHandler !== currentContextValue.controlSetToHoverHandler) {
      currentContextValue = { ...currentContextValue, controlSetToHoverHandler }
    }
    if (fullScreenHandler !== currentContextValue.fullScreenHandler) {
      currentContextValue = { ...currentContextValue, fullScreenHandler }
    }
    if (contextValue !== currentContextValue) {
      setContextValue(currentContextValue)
    }
    // init focus watcher, if loose focus -> pause a video
    // because of raf and requestVideoFrameCallback browsers optimization (they don't work after focus blured)
    if (!videoElement) return
    if (videoElement !== contextValue.videoElement) {
      setContextValue((currentContextValue) => ({ ...currentContextValue, videoElement }))
    }

    const onVisibilityChange = () => {
      if (!videoElement) return
      if (document.hidden) {
        videoElement.pause()
        setContextValue((ctx) => ({ ...ctx, isPaused: true, isInFocus: false }))
      } else {
        setContextValue((ctx) => ({ ...ctx, isInFocus: true }))
      }
    }

    const recalcPlayerBounding = () => {
      // needed to recalculate dimensions of playerBounding.
      setContextValue((ctx) => ({ ...ctx, playerBounding: getBoundingRectForElement(videoElement.parentNode) }))
    }

    window.onresize = recalcPlayerBounding
    document.onvisibilitychange = onVisibilityChange
    document.onfullscreenchange = recalcPlayerBounding
    document.onwebkitfullscreenchange = recalcPlayerBounding
    videoElement?.addEventListener('loadeddata', recalcPlayerBounding)

    window.screen?.orientation?.addEventListener('change', recalcPlayerBounding)
    return () => {
      window.onresize = null
      document.onfullscreenchange = null
      document.onwebkitfullscreenchange = null
      document.onvisibilitychange = null
      videoElement?.removeEventListener('loadeddata', recalcPlayerBounding)
      window.screen?.orientation?.removeEventListener('orientationchange', recalcPlayerBounding)
    }
  }, [videoElement])

  useEffect(() => {
    if (isIOS && browser === BROWSER_ENUM.SAFARI) {
      if (window.innerWidth > window.innerHeight) {
        if (isIOSFullScreen) {
          playerViewWrapperRef.current.style.height = IOS_PLAYER_HEIGHT
        }
      } else {
        playerViewWrapperRef.current.style.height = '100%'
      }
    }
  }, [window.innerWidth, window.innerHeight])

  // play/pause on space
  useEffect(() => {
    if (!videoElement) return
    const spaceKeyHandler = (event) => {
      const { isPlayDisabled, isPaused } = contextValue
      if (isPlayDisabled) return
      if (event.code !== 'Space') return
      document.activeElement?.blur()
      if (isPaused) playHandler()
      else pauseHandler()
    }
    document.addEventListener('keydown', spaceKeyHandler)
    return () => {
      document.removeEventListener('keydown', spaceKeyHandler)
    }
  }, [videoElement, contextValue?.isPaused, contextValue?.isPlayDisabled, playHandler, pauseHandler])

  useEffect(() => {
    return () => {
      playTimerRef.current = null
    }
  }, [])

  return <PlayerContext.Provider value={contextValue}>{children}</PlayerContext.Provider>
}
