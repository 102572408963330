export const HOME_PATH = '/'
export const VIDEO_PATH = '/:videoId'
export const DEFAULT_PATH = '*'
export const NEW_PLAYER_PATH = '/refactoring/:videoId'
export const ROUTER_CONFIG = {
  HOME: {
    path: HOME_PATH,
    createURL: () => '/',
  },
  VIDEO: {
    path: VIDEO_PATH,
    createURL: (videoId) => `/${videoId}`,
  },
  DEFAULT: {
    path: DEFAULT_PATH,
    createURL: () => '',
  },
  // Temporary URL
  NEW_PLAYER: {
    path: NEW_PLAYER_PATH,
    createURL: (videoId) => `/refactoring/${videoId}`,
  },
}
