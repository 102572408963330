import { useParams, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import { useEffect, useRef } from 'react'
import {
  LOCAL_STORAGE_SUB_ACCOUNT_ID_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
  SEARCH_PARAM_SUB_ACCOUNT_ID_KEY,
  SEARCH_PARAM_TOKEN_KEY,
} from '../configs'
import { fetchVideo } from '../services'
import { InteractivePlayerSchedule } from '../components/Interactivity/InteractivePlayerSchedule'
import '../assets/styles/styles.less'
import '../assets/styles/main-styles.less'
import { PlayerContextProvider } from '../contexts'
import { INTERACTIVE_PLAYER_HTML_ID, PLAYER_VIEW_HTML_ID } from '../constants/constants'
import { NotificationContextProvider } from '../contexts/NotificationContext'
import VideoContainer from '../components/VideoContainer'

/*
  Stands for:
    - inits components' structure
    - inits video ref HTML element
    - fetches video data
    - gets signal all parts are ready
    - shows spinner 'till all parts' got ready
*/
const NewPlayer = ({ video, setVideo }) => {
  const videoElementRef = useRef(null)
  const videoElement = videoElementRef.current

  const [messageApi, messageContextHolder] = message.useMessage({
    getContainer: () => document.getElementById(PLAYER_VIEW_HTML_ID),
    prefixCls: 'app-message',
  })

  const params = useParams()
  const [search] = useSearchParams()
  const tokenSearchParam = search.get(SEARCH_PARAM_TOKEN_KEY)
  const subAccountIdSearchParam = search.get(SEARCH_PARAM_SUB_ACCOUNT_ID_KEY)
  const videoId = params.videoId
  const videoData = window.videoData

  const fetchVideoFn = async () => {
    const data = await fetchVideo({ videoId, videoData }, tokenSearchParam)
    setVideo(data)
  }

  useEffect(() => {
    if (tokenSearchParam) {
      localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, tokenSearchParam)
    } else {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
    }
  }, [tokenSearchParam])

  useEffect(() => {
    if (subAccountIdSearchParam) {
      localStorage.setItem(LOCAL_STORAGE_SUB_ACCOUNT_ID_KEY, subAccountIdSearchParam)
    } else {
      localStorage.removeItem(LOCAL_STORAGE_SUB_ACCOUNT_ID_KEY)
    }
  }, [subAccountIdSearchParam])

  useEffect(() => {
    fetchVideoFn()
  }, [videoId, videoData])

  return (
    <PlayerContextProvider videoElement={videoElement}>
      <NotificationContextProvider messageApi={messageApi}>
        <div className="player-root interactive-player" id={INTERACTIVE_PLAYER_HTML_ID}>
          {messageContextHolder}
          <InteractivePlayerSchedule videoId={videoId} video={video} videoElement={videoElement}>
            <VideoContainer videoElementRef={videoElementRef} />
          </InteractivePlayerSchedule>
        </div>
      </NotificationContextProvider>
    </PlayerContextProvider>
  )
}

export default NewPlayer
