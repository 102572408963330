import { useClientContext, usePlayerContext } from '../../../hooks'
import { TRANSITION_TIME, transitionTemplate } from '../../../constants/constants'
import { AnimatePresence, motion } from 'framer-motion'
import { BROWSER_ENUM } from '../../../configs'

export const QuizAnswer = (props) => {
  const {
    answerId,
    index,
    backgroundObject: background,
    controlViewData,
    textData,
    inputType,
    isChecked,
    isCheckbox,
    onAnswerChooseHandler,
    getElementStyles,
    topRecalcValue,
    leftRecalcValue,
  } = props

  const { playerBounding } = usePlayerContext()
  const { isFullScreen, browser } = useClientContext()

  const template = {
    ...transitionTemplate,
    transition: { duration: TRANSITION_TIME + index, delay: index * 0.2 },
  }

  const correctedBounding = { ...playerBounding, x: 0, y: isFullScreen ? 0 : playerBounding.top }

  const answerControlStyles = getElementStyles(correctedBounding, controlViewData, topRecalcValue, leftRecalcValue)
  if (browser === BROWSER_ENUM.SAFARI) answerControlStyles.transition = '0s'

  return (
    <AnimatePresence>
      <motion.label
        {...template}
        style={getElementStyles(correctedBounding, background, topRecalcValue, leftRecalcValue)}
        className={`quiz-answer${isChecked ? ' quiz-answer-checked' : ''}`}
        key={background.id}
      >
        <input
          onInput={onAnswerChooseHandler}
          name={isCheckbox ? answerId : 'answer-group'}
          type={inputType}
          value={answerId}
          className="sr-only answer-control-native"
        />
        <span className="sr-only">{textData.text}</span>
      </motion.label>
      <motion.span
        {...template}
        className={`answer-control${isChecked ? ' answer-control-checked' : ''}`}
        style={answerControlStyles}
        key={controlViewData.id}
      ></motion.span>
      <motion.span
        {...template}
        className={`answer-text${isChecked ? ' answer-text-checked' : ''}`}
        style={getElementStyles(correctedBounding, textData, topRecalcValue, leftRecalcValue)}
        key={textData.id}
      ></motion.span>
    </AnimatePresence>
  )
}
