import { createContext, useEffect, useState } from 'react'
import { useSCORMContext } from '../hooks'
import { AnalyticsCollector } from '../utils/analytics-helpers/analytics'

const defaultAnalyticContextValue = {
  analyticCollector: new AnalyticsCollector(),
}
export const AnalyticContext = createContext(defaultAnalyticContextValue)

export const AnalyticContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState(defaultAnalyticContextValue)
  const { scorm } = useSCORMContext()

  useEffect(() => {
    if (!scorm) return
    setContextValue((ctx) => ({ ...ctx, analyticCollector: new AnalyticsCollector(scorm) }))
  }, [scorm])
  return <AnalyticContext.Provider value={contextValue}>{children}</AnalyticContext.Provider>
}
