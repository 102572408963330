import { InteractiveBuilder, InteractiveSchedulableItem, preloadMediaElement } from './helper-fns'
import { EVENT_TYPE, INTERACTIVE_ITEM_TYPE } from './types'

export class LinkBuilder extends InteractiveBuilder {
  static buildLinks(objects, slideData, objectsHashMap) {
    if (!objects) return null
    if (!slideData) return null
    if (!Array.isArray(objects)) return null
    if (objects.length === 0) return null

    const { startTime, endTime, slideId } = slideData

    const linksInstances = objects.map((object) => new InteractiveLink(object, objectsHashMap))
    objects.forEach((object, idx) => {
      const interactiveLinkInstance = linksInstances[idx]
      const startObjectTime = LinkBuilder.calcStartObjectTime(object, startTime, endTime)
      const endObjectTime = LinkBuilder.calcEndObjectTime(object, startTime, endTime)
      const objectScheduleMeta = {
        slideId,
        objectId: object.id,
        object,
        interactiveItemType: INTERACTIVE_ITEM_TYPE.LINK,
        interactiveItem: interactiveLinkInstance,
        startTime: startObjectTime,
        endTime: endObjectTime,
      }
      interactiveLinkInstance._setScheduleObjects(startObjectTime, {
        ...objectScheduleMeta,
        eventType: EVENT_TYPE.OBJECT_START,
      })
      interactiveLinkInstance._setScheduleObjects(endObjectTime, {
        ...objectScheduleMeta,
        eventType: EVENT_TYPE.OBJECT_END,
      })
      preloadMediaElement(object)
    })

    linksInstances.forEach((linkInstance) => {
      const startTime = linkInstance.getInstanceStartTime()
      const endTime = linkInstance.getInstanceEndTime()
      linkInstance._setStartEndTime(startTime, endTime ?? slideData.endTime)
    })

    return linksInstances
  }
}

export class InteractiveLink extends InteractiveSchedulableItem {
  constructor(object, objectsHashMap) {
    super(INTERACTIVE_ITEM_TYPE.LINK)
    this.objectsHashMap = objectsHashMap
    this.object = object
    this.id = object.id
  }
  getActionStrategy() {
    return this.object.action
  }
}
