import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import App from './App'
import { THEME_CONFIG } from '@/configs'
import { AnalyticContextProvider, ClientContextProvider, SCORMContextProvider, UserContextProvider } from './contexts'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: THEME_CONFIG.palette.colorPrimary,
          colorPrimaryActive: THEME_CONFIG.palette.colorPrimaryActive,
          colorPrimaryHover: THEME_CONFIG.palette.colorPrimaryHover,
          colorLink: THEME_CONFIG.palette.colorLink,
          colorLinkActive: THEME_CONFIG.palette.colorLinkActive,
          colorLinkHover: THEME_CONFIG.palette.colorLinkHover,
          colorError: THEME_CONFIG.palette.colorError,
          fontFamily: THEME_CONFIG.fonts.defaultFont,
        },
      }}
    >
      <ClientContextProvider>
        <UserContextProvider>
          <SCORMContextProvider>
            <AnalyticContextProvider>
              <App />
            </AnalyticContextProvider>
          </SCORMContextProvider>
        </UserContextProvider>
      </ClientContextProvider>
    </ConfigProvider>
  </BrowserRouter>,
)
