import styles from './button-transparent.module.less'

export const ButtonTransparent = (props) => {
  const { children, className, a11yDescription, ...otherProps } = props
  return (
    <button {...otherProps} className={`${className ? `${className} ` : ''}${styles.btn}`}>
      <span className="sr-only">{a11yDescription}</span>
      {children}
    </button>
  )
}
