import { createContext, useEffect } from 'react'
import Scorm from '../utils/scorm'

const defaultSCORMContextValue = {
  scorm: new Scorm(),
}
export const SCORMContext = createContext(defaultSCORMContextValue)

export const SCORMContextProvider = ({ children }) => {
  const { scorm } = defaultSCORMContextValue
  useEffect(() => {
    if (!window.videoData) return

    scorm.init()

    return () => {
      scorm.close()
    }
  }, [])
  return <SCORMContext.Provider value={defaultSCORMContextValue}>{children}</SCORMContext.Provider>
}
