import { GAP_FOR_NAVIGATION_EVENTS, MIN_GAP_BETWEEN_EVENTS } from '../constants'
import { MARK_TYPE } from './types'

export const setMarks = ({ accumulatorObject, accumalatorList, slide, slideMeta, videoDuration }) => {
  const { chapterTitle, id, nextNavigationSlide } = slide
  const { startTime, endTime, hasInteractivity } = slideMeta
  const markTime = startTime
  const percentedMarkTime = Math.min(((endTime + GAP_FOR_NAVIGATION_EVENTS) / videoDuration) * 100, 100)
  const markData = {
    time: markTime,
    isInteractive: hasInteractivity,
    type: hasInteractivity ? MARK_TYPE.INTERACTIVE : MARK_TYPE.SLIDE,
    slideId: id,
    // TODO do we need this?
    nextSlide: nextNavigationSlide,
    chapter: chapterTitle,
    slideEndTime: endTime,
    nextSlideBeginTime: endTime + MIN_GAP_BETWEEN_EVENTS,
  }
  accumulatorObject[percentedMarkTime] = markData
  accumalatorList.push(markData)
}
