import { API_URLS, ENVS } from '../configs'
import { logger } from '../utils'

const getUpdatedMetrics = async (quizMetrics) => {
  if (!quizMetrics) return
  try {
    const result = await fetch(`${ENVS.REACT_APP_API_URL}${API_URLS.ANALYTICS.createURL()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      keepalive: true,
      body: JSON.stringify(quizMetrics),
    }).then((res) => res.json())
    return result
  } catch (error) {
    logger.log('ERROR AT SAVING ANALYTICS: ', error)
    return null
  }
}
export const AnalyticsService = {
  getUpdatedMetrics,
}
