import { RANGE_BOUND } from './constants'
import { noop } from './helpers'

export const createIsTimeKeyInRangeWithCurrentTime = (currentTime) => (waitingOnTime) => {
  const diff = +(currentTime - +waitingOnTime).toFixed(2)
  // console.log('slideTime -> ', waitingOnTime)
  // console.log('diff -> ', diff)
  const isInRange = -RANGE_BOUND <= diff && diff <= RANGE_BOUND
  // console.log(`is in range -> ${-RANGE_BOUND} +${RANGE_BOUND}`, isInRange)
  return isInRange
}

export const eventWatcher = (schedule, currentTime) => {
  // console.log('-- time -> ', currentTime, ' <--')
  // console.log('timeKeysQueue in videoCallback -> ', schedule.timeKeysQueue)
  const isTimeKeyInRangeWithCurrentTime = createIsTimeKeyInRangeWithCurrentTime(currentTime)
  const key = schedule.timeKeysQueue.find(isTimeKeyInRangeWithCurrentTime)
  if (key === null || key === undefined) return
  // console.log('found key -> ', key)
  schedule.runActions(key)
}

export const createRAFCallback = (videoElement, videoSchedule, permanentCallback = noop) => {
  let videoTime = 0
  const rafCallback = () => {
    window.requestAnimationFrame(rafCallback)
    const schedule = videoSchedule
    if (!schedule) return
    const currentTime = videoElement?.currentTime
    // TODO what is this stands for?
    if (currentTime === 0) schedule.runActions(0)
    if (currentTime === videoTime) return
    videoTime = currentTime
    eventWatcher(schedule, currentTime)
    permanentCallback(schedule, currentTime)
  }
  return rafCallback
}

export const createSimpleWatcher = ({ shouldWatcherRun, callback = noop, videoElement, schedule }) => {
  if (!shouldWatcherRun) return
  if (typeof shouldWatcherRun !== 'function') return
  if (!videoElement) return
  if (!schedule) return
  let videoTime = 0
  const rafSimpleWatcherCallback = () => {
    if (!shouldWatcherRun()) return
    window.requestAnimationFrame(rafSimpleWatcherCallback)
    const currentTime = videoElement.currentTime
    if (currentTime === videoTime) return
    videoTime = currentTime
    callback(schedule, currentTime)
  }
  return rafSimpleWatcherCallback
}
