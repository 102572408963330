import { createContext, useEffect, useState } from 'react'
import { noop } from '../utils'

const defaultScheduledPlayerContextValue = {
  rewindOnSlideHandler: noop,
  rewindToPrevSlideHandler: noop,
  rewindToNextSlideHandler: noop,
}
export const ScheduledPlayerContext = createContext(defaultScheduledPlayerContextValue)

/*
  Stands for:
    - provides wrapped with interactivity logic handlers for player (play, pause, rewind etc), some handlers with business logic are passed from outside
*/
export const ScheduledPlayerContextProvider = (props) => {
  const { children, rewindOnSlideHandler, rewindToPrevSlideHandler, rewindToNextSlideHandler } = props
  const [contextValue, setContextValue] = useState(defaultScheduledPlayerContextValue)

  useEffect(() => {
    let currentContextValue = contextValue

    if (rewindOnSlideHandler !== currentContextValue.rewindOnSlideHandler) {
      currentContextValue = { ...currentContextValue, rewindOnSlideHandler }
    }
    if (rewindToPrevSlideHandler !== currentContextValue.rewindToPrevSlideHandler) {
      currentContextValue = { ...currentContextValue, rewindToPrevSlideHandler }
    }
    if (rewindToNextSlideHandler !== currentContextValue.rewindToNextSlideHandler) {
      currentContextValue = { ...currentContextValue, rewindToNextSlideHandler }
    }

    if (contextValue !== currentContextValue) {
      setContextValue(currentContextValue)
    }
  }, [rewindOnSlideHandler, rewindToPrevSlideHandler, rewindToNextSlideHandler])

  return <ScheduledPlayerContext.Provider value={contextValue}>{children}</ScheduledPlayerContext.Provider>
}
