// constants should be all caps, snake_case
export const PLAY_RATES = [0.25, 0.5, 0.75, 'Normal', 1.25, 1.5, 1.75, 2]
export const PLAY_RATES_MAP = {
  0.25: 0.25,
  0.5: 0.5,
  0.75: 0.75,
  1: 1,
  Normal: '1',
  1.25: 1.25,
  1.5: 1.5,
  1.75: 1.75,
  2: 2,
}
export const DEFAULT_VOLUME = 30
export const DEFAULT_PLAYBACK_RATE = PLAY_RATES_MAP.Normal
export const IOS_PLAYER_HEIGHT = 'calc(100vh - 80px)'
export const CANVAS_HEIGHT = 360
export const CANVAS_WIDTH = 640
export const CONTROLS_HEIGHT = 80
