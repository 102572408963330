export const pipe =
  (...fns) =>
  (param) =>
    fns.reduce((prevResult, fn) => fn(prevResult), param)

export const passThroughWith = (effectFn) => (data, idx, source) => {
  effectFn(data, idx, source)
  return data
}

export const identity = (i) => i
